<template>
  <el-card id="creatSeat">
    <header>
      <h2>拓客宝列表</h2>
      <div class="search">
        <div class="left">
          <div class="left-wrap">
            <span>员工列表</span>
            <el-row class="demo-autocomplete">
              <el-col :span="24">
                <!-- <div class="sub-title">员工列表</div> -->
                <el-autocomplete class="inline-input" v-model="activePhone" :fetch-suggestions="querySearch"
                  placeholder="请输入员工" @select="handleSelect" size="mini"></el-autocomplete>
              </el-col>
            </el-row>
            <!-- <el-input
              v-model="activePhone"
              placeholder="请输入员工"
              size="mini"
            ></el-input> -->
          </div>
        </div>
        <div class="right">
          <el-button type="primary" icon="el-icon-search" @click="handleSearch()" class="searchBtn"
            size="mini">查找</el-button>

          <el-button type="primary" icon="el-icon-refresh" @click="reset()" class="reset" size="mini">重置</el-button>
        </div>
      </div>
    </header>
    <!-- <hr> -->
    <hr>
    <main>
      <el-table :data="tableData" border class="table" ref="multipleTable" @selection-change="handleSelectionChange"
        header-cell-class-name="table-header">
        <el-table-column type="selection" width="55" align="center"></el-table-column>
        <el-table-column prop="id" label="序号" align="center" width="100"></el-table-column>
        <el-table-column v-for="(v, i) in TableTitleArr" :key="i" :prop="v.prop" :label="v.label"
          align="center"></el-table-column>
        <el-table-column label="操作" width="auto" align="center" style="dispaly: flex">
          <template #default="scope">
            <div class="button-list">
              <el-button type="primary" @click="upDateUser(scope.row)" size="mini">更绑员工</el-button>
              <!-- <el-button type="primary" @click="upgrade(scope.row)" size="mini"
                >升级</el-button
              > -->
            </div>
          </template>
        </el-table-column>
      </el-table>
      <!--分页  -->
      <div class="pagination">
        <el-pagination background layout="total, prev, pager, next,sizes,jumper" :current-page="pageIndex"
          :page-size="pageSize" :page-sizes="[10, 20, 50, 100, 200]" :total="pageTotal" @size-change="handleSizeChange"
          @current-change="handlePageChange"></el-pagination>
      </div>
    </main>
    <!--  创建拓客宝账号弹出框 -->
    <el-dialog title="升级成其他套餐" :visible.sync="seatVisible" width="30%" @close="closeSkb">
      <el-form label-width="100px">
        <el-form-item label="选择套餐">
          <el-select v-model="packageValue" filterable placeholder="请选择套餐">
            <el-option v-for="item in options" :key="item.type" :label="item.type" :value="item.type">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <div style="color: rgba(252, 117, 13, 1)">
            注意套餐不可以降级处理!
          </div>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="seatVisible = false">取 消</el-button>
          <el-button type="primary" @click="isUpgrade">确 定</el-button>
        </span>
      </template>
    </el-dialog>
    <!--  创建拓客宝账号弹出框 -->
    <el-dialog title="更换使用拓客宝账号的员工" :visible.sync="seatVisibleUser" width="30%" @close="closeSkbUser">
      <el-form label-width="100px">
        <el-form-item label="选择员工">
          <el-select v-model="userValue" filterable placeholder="请选择员工">
            <el-option v-for="item in userOptions" :key="item.id" :label="item.realname"
              :value="[item.id, item.realname]">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="seatVisibleUser = false">取 消</el-button>
          <el-button type="primary" @click="isUpdate">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </el-card>
</template>

<script>
import { ElMessage } from "@/assets/js/elementui-api";
let Message = new ElMessage();
import { phoneFindUser } from "@/api/phone";
import { userFindlist } from "@/api/user";
import {
  selectAccount,
  selectAccountUser,
  queryMeal,
  upgradeMeala,
  updateAccount,
} from "@/api/skb";
export default {
  data() {
    return {
      timer: null,
      userOptions: [],
      userValue: null,
      seatVisibleUser: false,
      packageValue: null,
      options: [],
      seatVisible: false,
      currentKh: null,
      uid: null,
      restaurants: [],
      TableTitleArr: [
        {
          prop: "createUserName",
          label: "绑定员工",
        },
        {
          prop: "type",
          label: "套餐类型",
        },
        {
          prop: "expiryTime",
          label: "过期时间",
        },
        {
          prop: "createTime",
          label: "创建时间",
        },
      ],
      numsArray: [], //选中号码的ID
      orderBySeatMoney: null,
      fileList: [],
      encryption: false,
      realname: null,
      roleName: null,
      parentld: null,
      id: null,
      activePhone: null,
      name: null,
      value: null,
      userId: null,
      form: {},
      tableData: [], //表格内绑定的数据
      pageTotal: 0, //页数
      pageIndex: 1, //当前在第几页
      pageSize: 10, //前端规定每页显示的数量
      page: 1, //第几页
      size: 10, //每页的数量
    };
  },
  async created() {
    this.isMonthEnd();
    this.parentld = localStorage.getItem("parentld");
    this.realname = localStorage.getItem("realname");
    this.roleName = localStorage.getItem("roleName");
    this.encryption = localStorage.getItem("encryption");
    await this.getCompanyUid();
    await this.getStaffInfo();
    await this.getList();
    await this.queryMealInfo();
    await this.getIsUsername();
    await this.getUserList();
  },
  methods: {
    // 更换拓客宝员工
    async isUpdate() {
      // updateAccount
      let params = {
        id: this.form.id,
        userId: this.userValue[0],
        createUserName: this.userValue[1],
      };
      let res = await updateAccount(params);
      console.log("更换拓客宝员工", res);
      if (res.data.statusCode == "00000") {
        Message.success(res.data.message);
        this.getList();
      } else {
        Message.error(res.data.message);
      }
      this.seatVisibleUser = false;
    },
    // 获取所员工信息
    async getUserList() {
      let res = await phoneFindUser();
      if (res.data.statusCode == "00000") {
        this.userOptions = res.data.data.user;
        this.userOptions.push({
          id: localStorage.getItem("id"),
          realname: localStorage.getItem("realname"),
        });
      }
      // console.log("获取所员工信息", res.data);
    },
    // 获取有员工的拓客宝账号
    async getIsUsername() {
      let params = { page: 1, size: this.pageTotal };
      let res = await selectAccount(params);
      console.log("res", res.data);
      if (res.data.data.length != 0) {
        this.restaurants = res.data.data.map((v) => {
          if (v.username) {
            v.value = v.createUserName;
            return v;
          }
        });
      }
    },
    // 关闭拓客宝弹框
    closeSkb() {
      this.packageValue = null;
    },
    // 关闭拓客宝弹框
    closeSkbUser() {
      this.userValue = null;
    },

    // 确认升级
    async isUpgrade() {
      let params = {
        uid: this.form.uid,
        type: this.packageValue,
      };
      let res = await upgradeMeala(params);
      if (res.data.statusCode == "00000") {
        console.log("查询套餐信息", res.data);
        Message.success("升级成功");
        this.getList();
      } else {
        Message.error(res.data.message);
      }
      this.seatVisible = false;
    },
    // 打开升级弹框
    upgrade(row) {
      console.log("row", row);
      this.form = row;
      this.seatVisible = true;
    },
    upDateUser(row) {
      console.log("row", row);
      this.form = row;
      this.seatVisibleUser = true;
    },

    // 查询套餐信息
    async queryMealInfo() {
      let params = {};
      let res = await queryMeal(params);
      if (res.data.statusCode == "00000") {
        console.log("查询套餐信息", res.data);
        this.options = res.data.data.meals;
      }
    },
    // 获取公司信息
    async getCompanyUid() {
      let res1 = await selectAccount({
        page: "1",
        size: "10",
      });
      let data = res1.data.data;
      // console.log("res1,re", res1);
      if (data.length != 0) {
        this.uid = data[0].uid;
      }
    },
    // 获取已开通员工信息
    async getStaffInfo() {
      // if (this.uid) {
      //   let res2 = await selectAccountUser({
      //     uid: this.uid,
      //   });
      //   if (res2.data.statusCode == "00000") {
      //     // this.tableData = res2.data.data;
      //     console.log("开通的员工", res2.data.data);
      //     let data = res2.data.data;
      //     this.restaurants = data.map((v) => {
      //       v.value = v.name;
      //       return v;
      //     });
      //   }
      // }
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
          0
        );
      };
    },
    handleSelect(item) {
      this.currentKh = item;
      console.log(item);
    },
    // 输入建议
    querySearch(queryString, cb) {
      var restaurants = this.restaurants;
      var results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    // 搜索
    handleSearch() {
      this.page = 1;
      this.tableData = [this.currentKh];
      if (!this.currentKh) {
        this.getList();
      }
      console.log("aaa", this.currentKh);
    },
    // 转换日期
    getYMDHMS(format, date) {
      if (!date) {
        return "";
      }
      date = new Date(date);
      const dataItem = {
        "Y+": date.getFullYear().toString(),
        "m+": (date.getMonth() + 1).toString(),
        "d+": date.getDate().toString(),
        "H+": date.getHours().toString(),
        "M+": date.getMinutes().toString(),
        "S+": date.getSeconds().toString(),
      };
      Object.keys(dataItem).forEach((item) => {
        const ret = new RegExp(`(${item})`).exec(format);
        if (ret) {
          format = format.replace(
            ret[1],
            ret[1].length === 1
              ? dataItem[item]
              : dataItem[item].padStart(ret[1].length, "0")
          );
        }
      });
      return format;
    },
    //选择table表格
    handleSelectionChange(val) {
      this.numsArray = [];
      val.forEach((item) => {
        this.numsArray.push(item.id);
      });
      // console.log(111111111, this.numsArray);
    },

    // 分页
    handlePageChange(val) {
      this.page = val;
      this.getList();
    },
    handleSizeChange(val) {
      this.size = val;
      this.getList();
    },
    // 月底隐藏删除按钮
    isMonthEnd() {
      let date = new Date();
      let currentMonth = date.getMonth();
      let nextMonth = ++currentMonth;
      let nextMonthFirstDay = new Date(date.getFullYear(), nextMonth, 1);
      let oneDay = 1000 * 60 * 60 * 24;
      let lastTime = new Date(nextMonthFirstDay - oneDay);
      let day = lastTime.getDate();
      console.log("当月最后一天", day);
      let currentDay = date.getDate(); // 获取当前日(1-31)
      console.log("当天", currentDay);
      if (currentDay == day) {
        return (this.lastDay = 1);
      } else {
        return (this.lastDay = 0);
      }
    },
    // 展示
    getList() {
      if (this.timer) {
        Message.warning("请勿频繁操作~");
      }
      clearTimeout(this.timer);

      this.timer = setTimeout(async () => {
        let params = { page: this.page, size: this.size };

        // console.log("按员工搜索");
        // if (this.currentKh) {
        //   params.id = this.currentKh.companyId;
        //   params.companyName = this.currentKh.companyName;
        // }

        let res = await selectAccount(params);
        console.log("res", res.data);
        if (res.data.data.length != 0) {
          this.pageTotal = res.data.total;
          this.tableData = res.data.data;
        }
        this.timer = null
      }, 500);
    },
    //重置
    reset() {
      this.currentKh = null;
      this.activePhone = null;
      this.getList();
    },

    //下载模板
    upload() {
      window.open("http://123.60.30.116:9005/seat-phone.xls", "_self");
    },
    handleRemove(file, fileList) {
      console.log(11111111111, file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    handleExceed(files, fileList) {
      Message.warning(
        `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length
        } 个文件`
      );
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    changeUpload(file, fileList) {
      this.file = file.raw;
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-button--success,
.el-button--primary {
  color: #FFF;
  background-color: #3278FE;
  border-color: #3278FE;

  &:hover {
    opacity: 0.7;
  }
}

#creatSeat {
  height: 100%;
  overflow-y: scroll;

  header {
    h1 {
      font-weight: normal;
    }

    .search {
      display: flex;
      margin-top: 10px;
      align-items: center;

      .left {
        display: flex;
        align-items: center;
        height: 100%;
        flex-wrap: wrap;
        flex: 1;
        line-height: 30px;

        .left-wrap {
          display: flex;
          align-items: center;

          span {
            margin: 10px;
            width: 28%;
            text-align: right;
            align-items: center;
            font-size: 12px;
          }
        }
      }

      .right {
        // margin-top: 20px;
        text-align: right;
        display: flex;
        /* justify-content: space-between; */
        justify-content: flex-end;
        align-items: center;
        height: 100%;
      }
    }
  }

  main {
    .pagination {
      text-align: right;
      margin: 20px 0;
    }
  }

  .uploadBtn {
    width: 68%;
  }
}
</style>